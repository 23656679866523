/** @format */

export type Option = {
	label: string
	allowedValues?: string[]
	mandatory?: boolean
	helperText?: string
	match?: number
	default?: string
	toQuote?: boolean
	regex?: string
	dependsOn?: any
}

export type Job = {
	label: string
	options: Option[]
}
export default class FormLabels {
	public static jobs: Job[] = [
		{
			label: 'parsing',
			options: [
				{
					label: 'projectId',
					mandatory: true,
					regex: "^[^'\"]*$"
				},
				{
					label: 'platform',
					allowedValues: ['saagie-aws', 'offline'],
					mandatory: true,
					default: 'saagie-aws'
				},
				{
					label: 'sourceType',
					allowedValues: ['serimax', 'rath-db', 'vsb-db', 'butting', 'custom'],
					mandatory: true,
					default: 'serimax'
				},
				{
					label: 'customPath',
					toQuote: true
				},
				{
					label: 'identifyWithBatch',
					allowedValues: ['True', 'False'],
					default: 'False'
				},
				{
					label: 'forceOverpassDataCheck',
					allowedValues: ['True', 'False'],
					default: 'False'
				},
				{
					label: 'datasetPath',
				},
				{
					label: 'log',
					allowedValues: ['FATAL', 'ERROR', 'WARN', 'INFO', 'DEBUG', 'TRACE'],
					default: 'INFO'
				},

			]
		},
		{
			label: 'centering',
			options: [
				{
					label: 'projectId',
					mandatory: true,
					regex: "^[^'\"]*$"
				},
				{
					label: 'platform',
					allowedValues: ['saagie-aws', 'offline'],
					mandatory: true,
					default: 'saagie-aws'
				},
				{
					label: 'centerFromIr',
					allowedValues: ['True', 'False'],
					default: 'True'
				},
				{
					label: 'forceOverpassDataCheck',
					allowedValues: ['True', 'False'],
					default: 'False'
				},
				{
					label: 'datasetPath',
				},
				{
					label: 'log',
					allowedValues: ['FATAL', 'ERROR', 'WARN', 'INFO', 'DEBUG', 'TRACE'],
					default: 'INFO'
				},
			]
		},
		{
			label: 'curve',
			options: [
				{
					label: 'projectId',
					mandatory: true,
					regex: "^[^'\"]*$"
				},
				{
					label: 'platform',
					allowedValues: ['saagie-aws', 'offline'],
					mandatory: true,
					default: 'saagie-aws'
				},
				{
					label: 'irOr',
					allowedValues: ['ir', 'or', 'both'],
					mandatory: true,
					default: 'ir'
				},
				{
					label: 'niter',
				},
				{
					label: 'ncores',
				},
				{
					label: 'log',
					allowedValues: ['FATAL', 'ERROR', 'WARN', 'INFO', 'DEBUG', 'TRACE'],
					default: 'INFO'
				},
				{
					label: 'onAWS',
					allowedValues: ['True', 'False'],
					mandatory: true,
					default: 'True'
				},
			]
		},
		{
			label: 'combination',
			options: [
				{
					label: 'projectId',
					mandatory: true,
					regex: "^[^'\"]*$"
				},
				{
					label: 'onAWS',
					allowedValues: ['True', 'False'],
					mandatory: true,
					default: 'True'
				},
				{
					label: 'irOr',
					allowedValues: ['ir', 'or', 'ir_and_or', 'iror_combined'],
					mandatory: true,
					default: 'ir'
				},
				{
					label: 'welded',
					allowedValues: ['True', 'False'],
					mandatory: true
				},
				{
					label: 'limitsWeldedDeg',
					/* eslint-disable-next-line */
					regex: '^\\d{1,3}\\s\\d{1,3}$',
					helperText: 'limitsWeldedDeg_helperText',
					match: 360,
					dependsOn: { "welded": 'True' },
					mandatory: true
				},
				{
					label: 'hsIr',
					/* eslint-disable-next-line */
					regex: '^(\\d\\.?\\d{0,2})((,| |, )\\d\\.?\\d{0,2})*$',
					helperText: 'value_numerical',
					dependsOn: { "irOr": 'iror_combined' },
					mandatory: true
				},
				{
					label: 'hsOr',
					/* eslint-disable-next-line */
					regex: '^(\\d\\.?\\d{0,2})((,| |, )\\d\\.?\\d{0,2})*$',
					helperText: 'value_numerical',
					dependsOn: { "irOr": 'iror_combined' },
					mandatory: true
				},
				{
					label: 'continuityDeg',
					/* eslint-disable-next-line */
					regex: '^\\d{0,2}[13579]$',
					helperText: 'continuityDeg_helperText',
					default: '35'
				},
				{
					label: 'cleanPreviousHiloCombi',
					allowedValues: ['True', 'False'],
					default: 'True'
				},
				{
					label: 'maxTempInMemory',
				},
				{
					label: 'log',
					allowedValues: ['FATAL', 'ERROR', 'WARN', 'INFO', 'DEBUG', 'TRACE'],
					default: 'INFO'
				},
			]
		},
		{
			label: 'consistency',
			options: [
				{
					label: 'projectId',
					mandatory: true,
					regex: "^[^'\"]*$"
				},
				{
					label: 'projectInfoSource',
					allowedValues: ['sharepoint', 'custom'],
					mandatory: true,
					default: 'sharepoint'
				},
				{
					label: 'centeredDataSource',
					allowedValues: ['saagie-aws', 'offline'],
					mandatory: true,
					default: 'saagie-aws'
				},
				{
					label: 'centeredDataLocalPath',
					mandatory: false
				},
				{
					label: 'filterJoints',
					allowedValues: ['True', 'False'],
					mandatory: true,
					default: 'False'
				},
				{
					label: 'jointsTable',
					mandatory: true,
					dependsOn: {"filterJoints": 'True'},
					default: 'NULL'
				},
				{
					label: 'customString',
					mandatory: true,
					dependsOn: {"projectInfoSource": 'custom'},
					default: ';;;;;;;;;;;;;;;;;;'
				}
			]
		},
		{
		    label: 'compatibility',
		    options: [
				{
					label: 'projectId',
					mandatory: true,
					regex: "^[^'\"]*$"
				},
				{
					label: 'hiloIr',
					mandatory: true,
					regex: "^(\\d\\.?\\d{0,2})((,| |, )\\d\\.?\\d{0,2})*$",
					default: '0.5 0.7 1.0 1.2 1.5 1.7 2.0',
					helperText: 'hiloIr_helperText'
				},
				{
					label: 'hsReport',
					mandatory: true,
					regex: "^\\d\\.?\\d{0,2}$",
					default: '1.5',
					helperText: 'hs_report_helperText'
				},
				{
					label: 'type',
					allowedValues: ['full', 'executive_summary'],
					default: 'full'
				},
				{
					label: 'packingList',
				},
				{
					label: 'public',
					allowedValues: ['True', 'False'],
					default: 'True'
				},
				{
					label: 'analysisOd',
					allowedValues: ['True', 'False'],
					default: 'False'
				},
				{
					label: 'hsReportOd',
					regex: "^\\d\\.?\\d{0,2}$",
					dependsOn: {"analysisOd": 'True'},
					helperText: 'hsReportOdHelperText'
				},
				{
					label: 'bestBatchNb',
					regex: "^\\d+$",
					default: '10',
					helperText: 'value_numerical'
				},
				{
					label: 'bundles',
					allowedValues: ['True', 'False'],
					default: 'False'
				},
				{
					label: 'embedConclusion',
					allowedValues: ['True', 'False'],
					default: 'False'
				},
				{
					label: 'wishedReference',
				},
				{
					label: 'wishedVersion',
					regex: "^\\d{2}$",
					helperText: 'wished_version_helperText'
				},
				{
					label: 'acceptanceCriteria',
					regex: "^[1-9][0-9]?$|^100$",
					default: '30',
					helperText: 'value_0_100'
				},
				{
					label: 'acceptanceCriteriaMod',
					regex: "^[1-9][0-9]?$|^100$",
					default: '20',
					helperText: 'value_0_100'
				},
				{
					label: 'acceptanceCriteriaIncompatible',
					regex: "^[1-9][0-9]?$|^100$",
					default: '90',
					helperText: 'value_0_100'
				},
 				{
 					label: 'sampling',
 					regex: "^\\d+$",
 					default: '7000',
					helperText: 'value_numerical'
 				},
		    ]
		},
	]
}
