import React, { useCallback, useEffect, useContext } from 'react';
import { TextField, Button, Grid, Box, Select, MenuItem, InputLabel, FormControl, CircularProgress } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Typo } from '@digital-at-vallourec/steel-design-system-react'
import FormLabels, { Option } from '../../utils/FormLabels'
import _ from 'lodash'
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { useTranslation } from 'react-i18next'
import requests from '../../api/api'
import { JobContext } from '../../stores/JobStore'
import { toast } from 'react-toastify'

type FormContainerProps = {
}

function Form(props: FormContainerProps) {
  const { t } = useTranslation()
  const theme = useTheme();
  const styles = {
    container: {
      padding: theme.spacing(3),
      backgroundColor: 'transparent'
    },
    input: {
      width: '100%',
    },
    button: {
      margin: theme.spacing(4) + ' auto ' + theme.spacing(4) + ' auto',
    },
    p4: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    label: {
      transform: "translate(0px, 16px) scale(1)",
      '& .MuiInputLabel-asterisk': {
        color: 'red'
      },
      '&.MuiInputLabel-shrink': {
        transform: "translate(0px, 0px) scale(0.75) !important"
      }
    }
  };
  const [selectedJob, setSelectedJob] = React.useState('')
  const [isFormReady, setIsFormReady] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(false)
  const [selectedValues, setSelectedValues] = React.useState<any[]>([]);
  const { jobDispatch } = useContext(JobContext)


  /**
   * Handle job change and initialize selectedValues
   *
   * @param event
   */
  const handleJobChange = (event: any) => {
    // Initialize field options
    setSelectedValues([])
    setSelectedJob(FormLabels.jobs[event.target.value].label);
  };


  /**
   * Check if field already have a selected value
   *
   * @param string: field
   * @return boolean
   */
  const isPartOfSelectedValues = (field: string) => {
    return selectedValues.filter((selectedOption) => { return selectedOption.label === field }).length > 0
  }


  /**
   * Get selected values from option label
   *
   * update on selectedValues change
   * @param string[]: allowedValues
   * @param string: label
   * @return string
   */
  const selectedValueByLabel = useCallback((allowedValues: string[], label: string) => {
    return allowedValues.findIndex((selectedOption: any) => { return selectedOption === selectedValues.filter((value: any) => value.label === label)[0].value })
  }, [selectedValues])


  /**
   * Check if all fields are valids
   *
   * update on selectedJob and selectedValues change
   */
  useEffect(() => {
    let value = true
    if (selectedJob !== '') {
      getMandatoryFields().forEach((field: string) => {
        if (!isPartOfSelectedValues(field)) {
          value = false
        }
      })
    }
    else {
      value = false
    }
    setIsFormReady(value)
    // eslint-disable-next-line
  }, [selectedValues, selectedJob]);


  /**
   * Get options of a job list by label
   *
   * update on selectedJob change
   * @param no params
   * @return Option[]
   */
  const handleFieldChange = (event: any, option: Option) => {
    const exist = selectedValues.filter((selectedOption) => { return selectedOption.label === option.label })[0]
    // Delete first occurence if it exists
    if (exist) {
      const arrayToSave = [...selectedValues]
      const index = arrayToSave.indexOf(exist);
      if (index > -1) {
        arrayToSave.splice(index, 1)
        setSelectedValues(arrayToSave);
      }
    }
    if (!(!option.allowedValues && event.target.value === "")) {
      setSelectedValues(selectedValues => [...selectedValues, { label: option.label, value: option.allowedValues ? option.allowedValues[event.target.value] : event.target.value }]);
    }
  };


  /**
   * Submit form values when clicking on launch button
   *
   * @param event
   */
  const handleSubmit = (event: any) => {
    event.preventDefault();
    setIsLoading(true)
    let jobParam: any = {}
    jobParam.jobOptions = { jobType: selectedJob }
    selectedValues.forEach((selectedValue) =>
      jobParam.jobOptions[selectedValue.label] = selectedValue.value
    )
    requests
      .launchJob(jobParam)
      .then((actual_job_id: string) => {
        jobDispatch({ type: 'set_actual_job_id', actual_job_id })
        jobDispatch({ type: 'set_selected_job', selected_job: jobParam.jobOptions })
        setIsLoading(false)
      })
      .catch((e: any) => {
        setIsLoading(false)
          toast.error(e.message)
      })
  };


  /**
   * Get options of a job list by label
   *
   * update on selectedJob change
   * @param no params
   * @return Option[]
   */
  const getOptionsByJobLabel = useCallback(() => {
    if (selectedJob !== '') {
      const findJob = FormLabels.jobs.map(j => j.label).indexOf(selectedJob)
      /* eslint-disable */
      return FormLabels.jobs[findJob].options
    }
    return []
  }, [selectedJob])


  /**
   * Check if a value is matching his regex (if it exists) and his matching sum (if it exists)
   *
   * update on selectedValues change
   * @param Options: option
   * @return boolean
   */
  const isValid = useCallback((option: Option) => {
    /* eslint-disable */
    const reg = new RegExp(option.regex ?? '');
    const testedValue = selectedValues.filter((selectedOption) => { return selectedOption.label === option.label })[0]?.value
    const equalToMatch = option.match && testedValue ? testedValue.split(' ').map(Number).reduce((a: number, b: number) => a + b) === option.match : true
    return ((reg.test(testedValue) && equalToMatch) || !testedValue)
  }, [selectedValues])


  /**
   * Check if a textField should be displayed depending on selected values from other fields
   *
   * update on selectedJob and selectedValues change
   * @param any: dependsOn
   * @return boolean
   */
  const isTextFieldDisplayed = useCallback((dependsOn: any) => {
    let value = 0
    if (!dependsOn) {
      value = + 1
    }
    else {
      selectedValues.forEach((selectedOption) => {
        if (dependsOn[selectedOption.label] === selectedOption.value) {
          value = + 1
        }
      })
    }
    return value
  }, [selectedJob, selectedValues])


  /**
   * get mandatory fields of the form
   *
   * update on selectedJob and isTextFieldDisplayed changes
   * @param no params
   * @return string[]
   */
  const getMandatoryFields = useCallback(() => {
    if (selectedJob !== '') {
      const findJob = FormLabels.jobs.map(j => j.label).indexOf(selectedJob)
      return FormLabels.jobs[findJob].options.filter(option =>
        option.dependsOn ? isTextFieldDisplayed(option.dependsOn) : option.mandatory
      ).map(option => option.label)
    }
    return []
  }, [selectedJob, isTextFieldDisplayed])


  /**
   * Set default options values
   * update on selectedJob change
   */
  useEffect(() => {
    if (selectedJob !== '') {
      setSelectedValues(selectedValues => [])
      getOptionsByJobLabel().forEach((option: Option) => {
        if (option.default && selectedValues.findIndex((selectedOption) => selectedOption.label === option.label) === -1) {
          setSelectedValues(selectedValues => [...selectedValues, { label: option.label, value: option.default }]);
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJob])

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%' }} >
      <Grid container rowSpacing={1} sx={styles.container} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6} sx={styles.p4}>

          <FormControl margin="dense" required fullWidth>
            <InputLabel id="job" sx={styles.label} >{t('Job')}</InputLabel>
            <Select
              data-testid="select_job"
              sx={styles.input}
              label="Job"
              labelId="job"
              size="medium"
              onChange={handleJobChange}
              variant="standard"
            >
              {FormLabels.jobs.map((option: Option, index: number) => (
                <MenuItem data-testid="menu-item" key={index} value={index}>
                  <Typo variant={'body1'}>{t(option.label)}</Typo>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sx={styles.p4}>
        </Grid>
        {_.map(getOptionsByJobLabel(), (option: Option) => (
          option.allowedValues ?
            <Grid item xs={6} sx={styles.p4} data-testid="options_fields">
              <FormControl required={option.mandatory} fullWidth>
                <InputLabel id={t(`${option.label}`) ?? ''} sx={styles.label} >{t(`${option.label}`)}</InputLabel>
                <Select
                  data-testid="select_job"
                  sx={styles.input}
                  label={option.mandatory ? t(`${option.label}`) + ' *' : t(`${option.label}`)}
                  labelId={t(`${option.label}`) ?? ''}
                  required={option?.mandatory}
                  size="medium"
                  value={isPartOfSelectedValues(option.label) ? selectedValueByLabel(option.allowedValues, option.label) : ''}
                  onChange={(event) => handleFieldChange(event, option)}
                  variant="standard"
                >
                  {option.allowedValues.map((val: any, index: number) => (
                    <MenuItem data-testid="menu-item" key={index} value={index}>
                      <Typo variant={'body1'}>{val}</Typo>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

            </Grid>
            : isTextFieldDisplayed(option.dependsOn) > 0 &&
            <Grid item xs={6} sx={styles.p4}>
              <TextField
                sx={[styles.input, { '& .MuiInputLabel-asterisk': { color: 'red' } }]}
                inputProps={{ pattern: option.regex }}
                onChange={(event) => handleFieldChange(event, option)}
                placeholder={'Your value here'}
                label={t(`${option.label}`)}
                helperText={(!isValid(option) && option.helperText) && t(`${option.helperText}`)}
                error={!isValid(option)}
                value={isPartOfSelectedValues(option.label) ? selectedValues.filter((value: any) => value.label === option.label)[0].value : ''}
                required={option?.mandatory}
                variant="standard"
              />
            </Grid>

        ))}
      </Grid>
      <Box textAlign='center'>
        <Button
          onFocusVisible={() => { }}
          variant="cta"
          disabled={!isFormReady}
          sx={styles.button}
          type="submit"
        >
          {isLoading ? <CircularProgress color="inherit" size={20} /> :
            <>
              <RocketLaunchIcon fontSize="medium" />
              <Typo>{t('Launch')}</Typo>
            </>
          }
        </Button>
      </Box>

    </form>
  );
}

export default Form;