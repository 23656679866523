/** @format */

import React, { Dispatch, useReducer } from 'react'

type State = {
	actualJobId: string,
	logs: string,
	selectedJob: any
}

type Action =
	| { type: 'set_actual_job_id'; actual_job_id: string }
	| { type: 'set_selected_job'; selected_job: any }
	| { type: 'set_logs'; logs: string }

const initialState: State = {
	actualJobId: '',
	logs: '',
	selectedJob: {},
}

export const JobContext = React.createContext<{
	jobState: State
	jobDispatch: Dispatch<Action>
}>({
	jobState: initialState,
	jobDispatch: () => null
})

const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case 'set_actual_job_id':
			return {
				...state,
				actualJobId: action.actual_job_id
			}
		case 'set_logs':
			return {
				...state,
				logs: action.logs
			}
		case 'set_selected_job':
			return {
				...state,
				selectedJob: action.selected_job
			}	}
}

export const JobProvider = (props: any) => {
	const [jobState, jobDispatch] = useReducer(reducer, initialState)

	return <JobContext.Provider value={{ jobState, jobDispatch }}>{props.children}</JobContext.Provider>
}
