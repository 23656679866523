/** @format */

import {
	Button,
	Drawer,
	Box,
	MenuItem,
	MenuList,
	Divider
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Menu, Close } from '@mui/icons-material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typo } from '@digital-at-vallourec/steel-design-system-react'
import { useNavigate } from "react-router-dom"

type MenuBurgerProps = {
	title?: string
	subtitle?: string
	username?: string
	settings?: Array<OptionProps>
}

type OptionProps = {
	key?: string
	label?: string
	onClick?: () => void
}

const MenuBurger = (props: MenuBurgerProps) => {
	const theme = useTheme();
	const styles = {
		menuBtn: {
		    marginRight: theme.spacing(4),
			padding: '0px',
			minWidth: '0px !important',
			minHeight: 35,
			backgroundColor: 'rgba(0, 0, 0, 0)',
			'&:hover': {
				backgroundColor: 'rgba(0, 0, 0, 0)'
			}
		},
		drawer: {
			width: '300px',
			height: '100%',
			boxShadow: '-4px 0px 8px #00000029',
			opacity: '1',
			marginTop: '',
			padding: '32px 24px'
		},
		drawerHeader: {
			display: "flex",
			alignItems: "center",
			padding: theme.spacing(0, 2),
			// necessary for content to be below app bar
			minHeight: theme.spacing(8),
			justifyContent: "flex-end"
		},
		box: {
			padding: theme.spacing(4),

		}
	};

	const navigate = useNavigate();
	const { t } = useTranslation()

	const [open, setOpen] = React.useState(false)

	const handleToggle = () => {
		setOpen(prevOpen => !prevOpen)
	}

	const handleClose = (event: React.MouseEvent<EventTarget>) => {
		setOpen(false)
	}

	const redirectToNewJob = (event: React.MouseEvent<EventTarget>) => {
		navigate('/new-job')
		handleClose(event)
	}

	const redirectToJobsList = (event: React.MouseEvent<EventTarget>) => {
		navigate('/logs-list')
		handleClose(event)
	}

	return (
		<>
			<Button
				onClick={handleToggle}
				sx={styles.menuBtn}
			>
				{!open ? <Menu color="action" fontSize="large" /> : <Close color="action" fontSize="large" />}
			</Button>
			<Drawer
				sx={styles.drawer}
				open={open}
				PaperProps={{
					elevation: 9, sx: {
						width: 'inherit'
					}
				}}
				anchor="left"
				onClose={handleClose}
			>
				<div style={styles.drawerHeader}></div>
				<Divider />
				<Box sx={styles.box}>
                    <MenuList>
					    <>
                        	<MenuItem onClick={redirectToNewJob}>
                        		<Typo variant='h4'>{t('Start a new job')}</Typo>
                        	</MenuItem>
                        	<MenuItem onClick={redirectToJobsList}>
                        		<Typo variant='h4'>{t('Jobs list & history')}</Typo>
                       		</MenuItem>
                        </>
                    </MenuList>
				</Box>
			</Drawer>
		</>
	)
}

export default MenuBurger
