/** @format */

import React, { ReactNode, useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'


//@ts-ignore
const initialState: any = {
	name: 'Smartengo Best Fit',
	subtitle: '',
	version: process.env.REACT_APP_VERSION,
	isHeaderVisible: true,
	pageTitle: 'Smartengo Best Fit',
	pageSubtitle: '',
	redirectUrl: '',
}

export const AppContext = React.createContext(initialState)

interface Props {
    children?: ReactNode
}

export const AppProvider = ( {children}: Props ) => {
	const [redirectUrl, setRedirectUrl] = useState('')
	const [pageTitle, setPageTitle] = useState(initialState.name)
	const [pageSubtitle, setPageSubtitle] = useState(initialState.subtitle)
	const [isHeaderVisible, setHeaderVisibility] = useState(true)
	const { i18n } = useTranslation()
	const [pageLoading, setPageLoading] = useState(false)
	const [pageLoadingFomDrawer, setPageLoadingFomDrawer] = useState(false)
	const [applicationMessage, setApplicationMessage] = useState({})

	moment.locale(i18n.language)

	const showSuccessMessage = (message:string) => {
		setApplicationMessage({ type: 'success', message: message })
	}

	const showErrorMessage = (message:string) => {
		setApplicationMessage({ type: 'error', message: message })
	}

	const showWarningMessage = (message:string) => {
		setApplicationMessage({ type: 'warning', message: message })
	}

	const showInfoMessage = (message:string) => {
		setApplicationMessage({ type: 'info', message: message })
	}

	//@ts-ignore
	return (
		<AppContext.Provider
			value={{
				...initialState,
				redirectUrl,
				pageTitle,
				pageSubtitle,
				isHeaderVisible,
				//@ts-ignore
				setRedirectUrl,
				//@ts-ignore
				setPageTitle,
				//@ts-ignore
				setPageSubtitle,
				//@ts-ignore
				setHeaderVisibility,
				pageLoading,
				pageLoadingFomDrawer,
				//@ts-ignore
				setPageLoading,
				setPageLoadingFomDrawer,
				applicationMessage,
				setApplicationMessage,
				showSuccessMessage,
				showErrorMessage,
				showWarningMessage,
				showInfoMessage
			}}
		>
			{children}
		</AppContext.Provider>
	)
}
