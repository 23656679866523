import React from 'react';
import i18next from 'i18next'
import { ThemeProvider } from '@mui/material';
import Router from './Router';
import { theme } from '@digital-at-vallourec/steel-design-system-react';
import { UserProvider } from './stores/UserStore'
import { AppProvider } from './stores/AppStore'
import { JobProvider } from './stores/JobStore'
import './i18n'
import { ToastContainer, Zoom } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

i18next.init({
	load: 'languageOnly',
	fallbackLng: 'en',
	returnNull: false,
})

const App = () => {
	return (
		<ThemeProvider theme={theme}>
			<ToastContainer
				autoClose={4000}
				draggable={false}
				transition={Zoom}
				hideProgressBar={true}
				closeButton={true}
			/>
			<AppProvider>
				<UserProvider>
					<JobProvider>
						<Router />
					</JobProvider>
				</UserProvider>
			</AppProvider>
		</ThemeProvider>
	);
}

export default App;
