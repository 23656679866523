export const responseHandle = (response: any) => {
    function getResponseData() {
        if(response.data){
            if(response.data.body){
                return response.data.body
            } else {
                return response.data
            }
        } else {
            return response
        }
    }

    const responseData = getResponseData()
    const data = responseData
    return data
}

function getMessage(error: any) {
    if(error.response.data.message) {
        return error.response.data.message
    } else {
        if(error.response.data.errorMessage){
            return error.response.data.errorMessage
        } else {
            return error.response.data
        }
    }
}

function getMessage2(error: any) {
    if(error.response.message){
        return error.response.message;
    } else {
        if(error.response.errorMessage){
            return error.response.errorMessage
        } else {
            return error.response
        }
    }
}

export const errorHandle = (error: any) => {
    let message = JSON.stringify(error)
    if (error.response) {
        if (error.response.data) {
            try {
                const data = JSON.parse(error.response.data)
                if (data.message) {
                    message = data.message
                } else if (data.errorMessage) {
                    message = data.errorMessage
                }
            } catch (tryError) {
                message = getMessage(error)
            }
        } else {
            try {
                const data = JSON.parse(error.response)
                if (data.message) {
                    message = data.message
                } else if (data.errorMessage) {
                    message = data.errorMessage
                }
            } catch (tryError) {
                message = getMessage2(error)
            }
        }
    } else {
        try {
            const data = JSON.parse(error)
            if (data.message) {
                message = data.message
            } else if (data.errorMessage) {
                message = data.errorMessage
            }
        } catch (tryError) {
            if(error.message){
                message = error.message
            } else {
                if(error.errorMessage){
                    message = error.errorMessage
                }
            }
        }
    }

    if (message.indexOf('Invalid Security Token') !== -1) {
        message = 'Invalid Security Token'
    } else if (message.indexOf('Unauthorized') !== -1) {
        message = 'Unauthorized'
    } else if (message.indexOf('Token is expired') !== -1) {
        message = 'Token is expired'
    }

    return message
}
