/** @format */

import {AppBar, Box, Toolbar} from '@mui/material'
import {Typo} from '@digital-at-vallourec/steel-design-system-react'
import React, {useContext} from 'react'
import MenuBurger from '../../menuBurger/MenuBurger'
import UserOptions from './UserOptions'
import {UserContext} from '../../../stores/UserStore'

type HeaderProps = {
	title?: string
	subtitle?: string
	username?: string
	settings?: Array<OptionProps>
}

type OptionProps = {
	key?: string
	label?: string
	onClick?: () => void
}

const Header = (props: HeaderProps) => {
 	const { authState } = useContext(UserContext)

	return (
		<AppBar position="static" sx={{ bgcolor: "primary.light", zIndex: "3000", position: "fixed" }}>
			<Toolbar>
				<Box display="flex" alignItems="center" flexGrow={1}>
					{authState === "SIGNED_IN" && <MenuBurger/>}
                    <div style={{display: 'flex', alignItems: 'baseline'}}>
                        <Typo variant='h1Light' color="#1A2981"> Smartengo </Typo>
                        <Typo variant='h2' color="#1A2981" sx={{marginLeft: '5px'}}> Best Fit </Typo>
                    </div>
					{authState === "SIGNED_IN" && <UserOptions />}

				</Box>
			</Toolbar>
		</AppBar>
	)
}

export default Header
