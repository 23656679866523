/** @format */

import {
	Container,
	Typography,
	Button
} from '@mui/material'
import SupportIcon from '@mui/icons-material/Support';
import CardContainer from '../../common/CardContainer'
import { useTheme } from '@mui/material/styles'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import React from 'react'
import { useTranslation } from 'react-i18next'

const SupportCard = () => {
  const { t } = useTranslation()
  const theme = useTheme();
  const styles = {
    container: {
        py: theme.spacing(5),
        px: theme.spacing(5),
        margin: 0,
        maxWidth: 'none !important'
    },
    cardContainer: {
        background: '#1A2981',
        width:'100%',
        textAlign:'center',
        alignItems:'center',
        maxWidth: 'none !important'
    },
    supportIcon: {
        color:'white',
        fontSize: 50
    },
    supportBtn: {
        '&:hover': {
            color: "#1A2981",
            backgroundColor: 'white'
        },
        margin: '20px'
    }
  };
	return (
      <Container disableGutters sx={styles.container}>
        <CardContainer style={styles.cardContainer} element={
        <div style={{display: 'flex',
                     alignItems: 'center',
                     flexDirection: 'column'}}>
            <SupportIcon sx={styles.supportIcon} />
            <Typography variant={'h4'} sx={{color:'white', fontWeight:'bold'}}>{t('Help & Support')}</Typography>
            <Typography sx={{color:'white'}} variant="h6">{t('support_card_text')}</Typography>
            <Button
              onFocusVisible={() => {}}
              size="large"
              variant="secondary-dark"
              sx={styles.supportBtn}
            >
            <OpenInNewOutlinedIcon sx={{paddingRight: '8px'}}/>
            <Typography variant={'h4'} sx={{fontWeight:'bold'}}>{t('Contact Us')}</Typography>
            </Button>
        </div>
        } />
      </Container>
	)
}

export default SupportCard
