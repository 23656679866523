/** @format */

import {Button, Fade, Menu, MenuItem} from '@mui/material'
import {ArrowDropDown} from '@mui/icons-material'
import {useTheme} from '@mui/material/styles'
import React, {useContext} from 'react'
import {UserContext} from '../../../stores/UserStore'
import {useTranslation} from 'react-i18next'
import packageInfo from './../../../../package.json'

const UserOptions = () => {
    const theme = useTheme();
	const { t } = useTranslation()
    const styles = {
      btn: {
            textTransform: 'none',
            color: theme.palette.primary.main,
            marginLeft: 'auto',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0)'
            },
      },
      menu: {
      			display: "flex",
      			alignItems: "center",
      			padding: theme.spacing(0, 2),
      			// necessary for content to be below app bar
      			minHeight: theme.spacing(13),
      			justifyContent: "flex-end",
		  		marginTop: theme.spacing(6)
      },
		menuItem: {
			width: theme.spacing(17),
			justifyContent: "center"
		}
    };
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const [open, setOpen] = React.useState(false)
	const { currentUser, signOut } = useContext(UserContext)

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
		setOpen(!open)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
			<Button
							aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick} sx={styles.btn}>
				{currentUser && currentUser.firstName + ' ' + currentUser.lastName}
				<ArrowDropDown />
			</Button>

			<Menu
				id="fade-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				TransitionComponent={Fade}
				sx={styles.menu}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<MenuItem sx={styles.menuItem} onClick={handleClose}>{'v' + packageInfo.version}</MenuItem>
				<MenuItem sx={styles.menuItem} onClick={signOut}>{t('sign_out')}</MenuItem>
			</Menu>

		</>
	)
}

export default UserOptions
