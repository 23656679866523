import React from 'react';
import BtnNextPage from '../../common/BtnNextPage'
import { PageTitle } from '@digital-at-vallourec/steel-design-system-react';

type BannerProps = {
  breadcrumbsLabel: string
  breadcrumbsLink: string
  buttonTitle: string
  buttonSubtitle: string
  buttonLink: string
}

function Banner(props: BannerProps) {
  const styles = {
    bannerDiv: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  };

  return (
    <div style={styles.bannerDiv} >
      <PageTitle
        breadcrumbRoutes={[
          {
            breadcrumb: props.breadcrumbsLabel,
            path: props.breadcrumbsLink
          },
        ]}
        isBreadcrumbVisible
        isArrowBackVisible={false}
        title={props.breadcrumbsLabel}
        variant="h1"
      />
      <BtnNextPage title={props.buttonTitle} subtitle={props.buttonSubtitle} link={props.buttonLink} />
    </div>
  );
}

export default (Banner);