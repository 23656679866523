import React from 'react';
import { useParams } from 'react-router-dom';
import LoadingOverlay from '../components/common/LoadingOverlay';

const RedirectTo = () => {

  const { to }: any = useParams();    
  window.location.replace(process.env.REACT_APP_LOGIN_URL+'/' + (to ? to : 'logout') + '?app='+process.env.REACT_APP_ID);
  return <LoadingOverlay open={true} />
}

export default RedirectTo