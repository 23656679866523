import React from 'react';
import { useTheme } from '@mui/material/styles'
import { Container } from '@mui/material'

type CardContainerProps = {
	element?: JSX.Element,
	style?: any
}

function CardContainer(props: CardContainerProps) {
  const theme = useTheme();
  const styles = {
    card: {
      alignItems: 'flex-start',
      borderRadius: '8px',
      boxShadow: theme.shadows[1],
      display: 'flex',
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      margin: '0',
      flexDirection: 'column'
    }
  };
  return (
          <Container disableGutters
            sx={[styles.card, props.style]}>
                {props.element}
          </Container>

  );
}

export default CardContainer;