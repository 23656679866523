import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';


const LoadingOverlay = ( props: {open: boolean} ) => {
   return (
      <Backdrop open={props.open}>
         <CircularProgress color="inherit" />
      </Backdrop>
   );
};

export default React.memo(LoadingOverlay);