/** @format */

import axios from 'axios'
import Cookies from 'js-cookie'
import { errorHandle } from '../utils/callback-handle'

const TOKEN_COOKIE = '_ssoToken'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}`,
	headers: {
		'Content-Type': 'application/json',
		'VLR-Authorization': btoa(JSON.stringify({ id: process.env.REACT_APP_ID }))
	}
})

instance.interceptors.request.use(
	request => {
		const idToken = Cookies.get(TOKEN_COOKIE)
		if (!idToken) {
			request.cancelToken = new axios.CancelToken(cancel => cancel('Token is expired'))
		} else {
		    if(request && request.headers){
			    request.headers['Authorization'] = idToken
            }
		}
		return request
	},
	error => {
		return Promise.reject(error)
	}
)


const manageResponse = (response: any) => {
	const result = response.data.body ? response?.data?.body : response.data
	if (typeof result == 'string' && !result.startsWith('http')) {
		try {
			return JSON.parse(result)
		} catch (e) {
			return result
		}
	}
	return result

}

const requests = {
	launchJob: async (jobParam: {
		jobOptions: Array<{ option: string; value: string }>
	}) => {
		try {
			const response = await instance.post(`/jobs`, jobParam.jobOptions)
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(errorHandle(error))
		}
	},

	getJobs: async () => {
		try {
			const response = await instance.get(`/jobs`)
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(errorHandle(error))
		}
	},

	getLogs: async (jobId: string) => {
		try {
			const url = '/jobs/' + jobId + '/logs'
			const response = await instance.get(url)
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(errorHandle(error))
		}
	},

	getJob: async (jobId: string) => {
		try {
			const url = '/jobs/' + jobId
			const response = await instance.get(url)
			return manageResponse(response)
		} catch (error) {
			console.log(error)
			throw new Error(errorHandle(error))
		}
	},
}
export default requests
