import React, { useEffect, useContext } from 'react';
import { useTheme } from '@mui/material/styles'
import { SectionTitle } from '@digital-at-vallourec/steel-design-system-react';
import { Container } from '@mui/material'
import CardContainer from '../components/common/CardContainer'
import LogsBox from '../components/common/LogsBox'
import Banner from '../components/layout/banner/Banner'
import Form from '../components/form/Form'
import SupportCard from '../components/layout/supportCard/SupportCard'
import { useTranslation } from 'react-i18next'
import { JobContext } from '../stores/JobStore'
import requests from '../api/api'
import { UserContext } from '../stores/UserStore'
import { toast } from 'react-toastify'


function NewJob() {
  const { t } = useTranslation()
  const theme = useTheme();
  const styles = {
    divBody: {
      backgroundColor: theme.palette.secondary.light,
      minHeight: '90vh'
    },
    container: {
      pt: theme.spacing(12),
      px: theme.spacing(5),
      margin: '0',
      maxWidth: 'none !important'
    },
    cardContainers: {
      marginTop: theme.spacing(3),
      display: 'flex',
      justifyContent: 'space-between',
      minHeight: '50vh'
    },
    cardContainer: {
      background: 'white',
      width: '48%',
      minHeight: 'inherit'
    }
  };

  const { jobState, jobDispatch } = useContext(JobContext)
  const [logs, setLogs] = React.useState('')
  const { setAuthState } = useContext(UserContext)

  /**
   * set default logs if actualJobId is empty
   */
  useEffect(() => {
    if (jobState.actualJobId === '') {
      setLogs('Please launch your job to show logs ...')
    }
  }, [jobState.actualJobId])

  /**
   * Init actualJobId and selectedJob on component creation
   *
   * Permit to re-initialize logs on page change
   */
  useEffect(() => {
    jobDispatch({ type: 'set_actual_job_id', actual_job_id: '' })
    jobDispatch({ type: 'set_selected_job', selected_job: {} })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Set and Generate logs when clicking on launch button (which is represented by an actualJobId update)
   */
  useEffect(() => {
    // logs are displayed only when actualJobId is not empty
    if (jobState.actualJobId !== '') {
      setLogs('Job ID : ' + jobState.actualJobId)
      // detect if jobType is Parsing, curve, combination or centering
      const jobType = jobState.selectedJob?.jobType[0].toUpperCase() + jobState.selectedJob?.jobType.substr(1).toLowerCase()
      setLogs((prevState) => (
        prevState + '\n' + jobType + ' data processing launched ...\n'
      ));
      // init log counter at 0
      let count = 0;
      // try to get the logs every 5 seconds until the job is finished
      const interval = setInterval(() => {
        requests
          .getLogs(jobState.actualJobId)
          .then((data: any) => {
            // get delta of logs to get only undisplayed logs
            const endCount = data.logs.length;
            for (let i = count; i < endCount; i++) {
              setLogs((prevState) => (
                // Generic Object Injection Sink sast-typescript returned but not dangerous
                // eslint-disable-next-line
                prevState + "\n" + data.logs[i].message
              ));
            }
            count = endCount
            requests
              .getJob(jobState.actualJobId)
              .then((dataJob: any) => {
                // if job is finished, then clear interval and end logs generation
                if (dataJob.status === 'SUCCEEDED' && data.logs.length > 0) {
                      setLogs((prevState) => (
                        prevState + "\n\nData processing completed"
                      ));
                      clearInterval(interval)
                }
                else if (dataJob.status === 'FAILED'){
                      setLogs((prevState) => (
                        prevState + "\n\nData processing failed"
                      ));
                      clearInterval(interval)
                }
              })
              .catch((e: any) => {
                if (e.resultCode === 'error.expired.token') {
                  setAuthState('LOGIN')
                } else {
                  toast.error(e.message)
                }
              })
          })
          .catch((e: any) => {
            if (e.resultCode === 'error.expired.token') {
              setAuthState('LOGIN')
              toast.error(e.message)
            }
          })

      }, 5000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobState.actualJobId]);

  return (
    <div style={styles.divBody}>
      <Container disableGutters sx={styles.container}>
        <Banner breadcrumbsLabel={t('Start a new job')}
          breadcrumbsLink={'/new-job'}
          buttonTitle={t('Jobs List & History') ?? ''}
          buttonSubtitle={t('Access to your jobs') ?? ''}
          buttonLink={"/logs-list"} />
        <div style={styles.cardContainers}>
          <CardContainer style={styles.cardContainer} element={
            <><SectionTitle
              description={t('Select your job options') ?? ''}
              title={t('Job settings')}
            />
              <Form></Form>
            </>
          }
          />
          <CardContainer style={styles.cardContainer} element={
            <>
              <SectionTitle
                description={t('Your job details displaying here') ?? ''}
                title={t('Logs')}
              />
              <LogsBox text={logs} />
            </>
          }
          />
        </div>
      </Container>
      <SupportCard />
    </div>
  );
}

export default NewJob;