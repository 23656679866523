import React, { FC, useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import LogsList from './pages/LogsList';
import NewJob from './pages/NewJob';
import RedirectTo from './pages/RedirectTo';
import { UserContext } from './stores/UserStore'
import Header from './components/layout/header/Header'
import Footer from './components/layout/footer/Footer'

type ProtectedRoutePath = {
  children: any;
};


const ProtectedRoute: FC<ProtectedRoutePath> = ({ children, ...rest }) => {
  const { authState } = useContext(UserContext)
  const isAuthenticated = (authState === 'SIGNED_IN');
  const isRefreshToken = (authState === 'REFRESH_TOKEN');
  const isAccessDenied = (authState === 'ACCESS_DENIED');
  const isWaitingAccess = (authState === 'WAITING_FOR_ACCESS');

  if (isAuthenticated) {
    return (children)
  }
  else {
    if(isRefreshToken){
      return (<Navigate to={{ pathname: '/redirect/refresh' }} />)
    } else if(isAccessDenied){
      return (<Navigate to={{ pathname: '/redirect/request' }} />)
    } else if (isWaitingAccess) {
      return (<Navigate to={{ pathname: '/redirect/waiting-access' }} />)
    } else {
      return (<Navigate to={{ pathname: '/redirect/logout' }} />)
    }
  }
}

const Router = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/redirect/:to" element={<RedirectTo />}></Route>
        <Route path='/' element={<ProtectedRoute><Home /></ProtectedRoute>} />
        <Route path='/new-job' element={<ProtectedRoute><NewJob /></ProtectedRoute>} />
        <Route path='/logs-list' element={<ProtectedRoute><LogsList /></ProtectedRoute>} />
      </Routes>
      <Footer />
    </>
  );
}
export default Router;
