import React, { useEffect, useContext } from 'react';
import requests from '../../api/api'
import { useTheme } from '@mui/material/styles'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import moment from 'moment'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { CircularProgress, Tooltip } from '@mui/material'
import { JobContext } from '../../stores/JobStore'
import { toast } from 'react-toastify'

function JobsTable() {
  const theme = useTheme();
  const styles = {
    loaderDiv: {
      width: '100%',
      height: '100%',
      color: theme.palette.primary.main,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  };

  const [jobs, setJobs] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)
  const { jobDispatch } = useContext(JobContext)


  const requestJobs = () => {
    requests
      .getJobs()
      .then((data: any) => {
        setJobs(data.sort(
          (objA: any, objB: any) => objB.launchOn - objA.launchOn,
        ))
        setIsLoading(false)
      })
      .catch((e: any) => {
          toast.error(e.message)
      })
  }

  useEffect(() => {
    if (jobs.length === 0) {
      requestJobs()
    }
    const interval = setInterval(() => {
      requestJobs()
    }, 5000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: GridColDef[] = [
    { field: 'jobType', headerName: 'Job Type', headerAlign: 'center', align: 'center', flex: 1 },
    { field: 'projectId', headerName: 'Project ID', headerAlign: 'center', align: 'center', flex: 1,
        renderCell: params => {
            return params?.value ? <Tooltip title={params?.value}>
                                       <div>{params?.value}</div>
                                   </Tooltip> : ''
        }
    },
    {
      field: 'launchOn', headerName: 'Launch On', flex: 1, valueFormatter: params =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"), headerAlign: 'center', align: 'center'
    },
    { field: 'launchBy', headerName: 'Launch By', headerAlign: 'center', align: 'center', flex: 1 },
    {
      field: 'status', headerName: 'Status', headerAlign: 'center', align: 'center', flex: 1,
      renderCell: params => {
        let color = 'transparent'
        switch (params?.value) {
          case 'IN_PROGRESS':
          case 'RUNNABLE':
          case 'STARTING':
          case 'RUNNING':
          case 'SUBMITTED':
            color = '#009ede'
            break
          case 'SUCCEEDED':
            color = 'green'
            break
          case 'FAILED':
            color = 'red'
            break
        }
        return params?.value ? <Tooltip title={params?.value}>
                                 <RadioButtonCheckedIcon style={{ color: color }} />
                               </Tooltip> : ''
      },
    }
  ];

  const onRowsSelectionHandler = (id: string) => {
      jobDispatch({ type: 'set_actual_job_id', actual_job_id:id })
      jobDispatch({ type: 'set_selected_job', selected_job: jobs.filter((j: any) => j.ID === id)[0] })
    };

  return (
    isLoading ? <div style={styles.loaderDiv}><CircularProgress color="inherit" size={50} /> </div> :

      <DataGrid
        sx={{
          width: "100%",
          '.MuiDataGrid-columnHeader--moving': {
            background: 'white',
          }
        }}
        onSelectionModelChange={(ids) => onRowsSelectionHandler(ids[0].toString())}
        rows={jobs}
        columns={columns}
        getRowId={(row) => row.ID}
      />

  );
}

export default JobsTable;