import React, { useEffect, useContext } from 'react';
import { useTheme } from '@mui/material/styles'
import { SectionTitle, Typo } from '@digital-at-vallourec/steel-design-system-react';
import { Container, Button } from '@mui/material'
import CardContainer from '../components/common/CardContainer'
import SupportCard from '../components/layout/supportCard/SupportCard'
import { useTranslation } from 'react-i18next'
import JobsTable from '../components/jobsTable/JobsTable'
import LogsBox from '../components/common/LogsBox'
import Banner from '../components/layout/banner/Banner'
import { JobContext } from '../stores/JobStore'
import { UserContext } from '../stores/UserStore'
import requests from '../api/api'
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { toast } from 'react-toastify'

function LogsList() {
  const { t } = useTranslation()
  const theme = useTheme();
  const styles = {
    divBody: {
      backgroundColor: theme.palette.secondary.light,
      minHeight: '90vh'
    },
    container: {
      pt: theme.spacing(12),
      px: theme.spacing(5),
      margin: "0",
      maxWidth: 'none !important'
    },
    cardContainers: {
      marginTop: theme.spacing(3),
      display: 'flex',
      justifyContent: 'space-between',
      minHeight: '50vh'
    },
    cardContainer: {
      background: 'white',
      width: '48%',
      minHeight: 'inherit'
    },
    supportBtn: {
      '&:hover': {
        color: "#1A2981",
        backgroundColor: 'white'
      },
      margin: '20px'
    }
  };
  const { setAuthState } = useContext(UserContext)
  const { jobState, jobDispatch } = useContext(JobContext)
  const [ isActualJobEnded, setIsActualJobEnded ] = React.useState(false)
  const [logs, setLogs] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)


  /**
   * Init actualJobId and selectedJob on component creation
   *
   * Permit to re-initialize logs on page change
   */
  useEffect(() => {
    jobDispatch({ type: 'set_actual_job_id', actual_job_id: '' })
    jobDispatch({ type: 'set_selected_job', selected_job: {} })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  /**
   * set default logs if actualJobId is empty or generateLogs if not
   */
  useEffect(() => {
    if (jobState.actualJobId !== '') {
      setIsActualJobEnded(false)
      generateLogs()
    }
    else {
      setLogs('Please select a job to show logs ...')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobState.actualJobId])

  /**
   * Set and Generate logs when clicking on a job in table or on refresh btn
   */
  const generateLogs = () => {
    setIsLoading(true)
    // display job info logs in case request failed
    if (jobState.selectedJob?.status !== "SUCCEEDED") {
      setLogs('Job ID : ' + jobState.actualJobId)
      const jobType = jobState.selectedJob?.jobType[0].toUpperCase() + jobState.selectedJob?.jobType.substr(1).toLowerCase()
      setLogs((prevState) => (
        prevState + '\n' + jobType + ' data processing launched ...\n'
      ));
    }
    requests
      .getLogs(jobState.actualJobId)
      .then((data: any) => {
        setLogs('Job ID : ' + jobState.actualJobId)
        const jobType = jobState.selectedJob?.jobType[0].toUpperCase() + jobState.selectedJob?.jobType.substr(1).toLowerCase()
        setLogs((prevState) => (
          prevState + '\n' + jobType + ' data processing launched ...\n'
        ));
        data.logs.forEach((log: any) => {
          setLogs((prevState) => (
            prevState + "\n" + log.message
          ));
        })
        // check status of our actual job (avoid conflict with jobTable where the job can
        // be succeeded but not here because of multiple requests simulateously)
        requests
          .getJob(jobState.actualJobId)
          .then((dataJob: any) => {
            // if job is finished, then end logs generation
            if (dataJob.status === 'SUCCEEDED') {
              setLogs((prevState) => (
                prevState + "\n\nData processing completed"
              ));
                if(!isActualJobEnded) {
                    setIsActualJobEnded(true)
                }
            }
            else if (dataJob.status === 'FAILED') {
              setLogs((prevState) => (
                prevState + "\n\nData processing failed"
              ));
                if(!isActualJobEnded) {
                    setIsActualJobEnded(true)
                }
            }
        if(isActualJobEnded || dataJob.status !== 'SUCCEEDED' || dataJob.status !== 'FAILED' ) {
            setIsLoading(false)
        }
          })

      })
      .catch((e: any) => {
        setIsLoading(false)
        if (e.resultCode === 'error.expired.token') {
          setAuthState('LOGIN')
        } else {
          toast.error(e.message)
        }
      })
  }

  return (
    <div style={styles.divBody}>
      <Container disableGutters sx={styles.container}>
        <Banner breadcrumbsLabel={t('Jobs list & history')}
          breadcrumbsLink={'/logs-list'}
          buttonTitle={t('Start a new job') ?? ''}
          buttonSubtitle={t('Access to your jobs') ?? ''}
          buttonLink={"/new-job"} />
        <div style={styles.cardContainers}>
          <CardContainer style={styles.cardContainer} element={
            <>
              <SectionTitle
                description={t('Select your job options') ?? ''}
                title={t('Jobs list')}
              />
              <JobsTable />
            </>
          }
          />
          <CardContainer style={styles.cardContainer} element={
            <>
              <div style={{
                display: "flex",
                justifyContent: "space-between",
                width: "98%"
              }}>
                <SectionTitle
                  description={t('Your job details displaying here') ?? ''}
                  title={t('Logs History')}
                />
                {(jobState.actualJobId !== '' && !isActualJobEnded )
                  && <Button variant={'cta'} onClick={() => generateLogs()}>
                    <AutorenewIcon sx={{ paddingRight: "8px" }} />
                    <Typo variant={'subtitle1'}>
                      Refresh
                    </Typo>
                  </Button>}
              </div>
              <LogsBox text={logs} isLoading={isLoading} />
            </>
          }

          />
        </div>
      </Container>
      <SupportCard />


    </div>
  );
}

export default LogsList;