import React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material'
import "@fontsource/roboto-mono"
import { useTheme } from '@mui/material/styles'

type LogsBoxProps = {
	text?: string,
	isLoading?: boolean
}

function LogsBox(props: LogsBoxProps) {
  const theme = useTheme();
  const styles = {
    box: {
        background: '#f0f0f0',
        borderRadius: '8px',
        width: '92%',
        height: '100%',
        margin: '2%',
        padding: '2%',
        maxHeight: '500px'
    },
    loaderDiv: {
      width: '100%',
      height: '100%',
      color: theme.palette.primary.main,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  };

  return (
    <Box component="div" sx={[styles.box, { overflow: 'auto' }]}>
          {props.isLoading ? <div style={styles.loaderDiv}><CircularProgress color="inherit" size={50} /> </div> :
            <Typography variant={ 'body1' } display="block" sx={{ fontFamily: 'Roboto Mono', whiteSpace: 'pre-line' }}>{props.text}</Typography>
          }
    </Box>
  );
}

export default LogsBox;