/** @format */

import axios from 'axios'
import Cookies from 'js-cookie'
import React, { ReactNode, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import LoadingOverlay from '../components/common/LoadingOverlay'
import { errorHandle, responseHandle } from '../utils/callback-handle'

const initialState: any = {
	user: null,
	settings: []
}

export const UserContext = React.createContext(initialState)

interface Props {
    children?: ReactNode
}

export const UserProvider= ( {children}: Props ) => {
	const TOKEN_COOKIE = '_ssoToken'

	const [currentUser, setCurrentUser] = useState(null)
	const [settings, setSettings] = useState(null)
	const [authState, setAuthState] = useState('LOGIN')
	const [isLoading, setLoading] = useState(true)

	const authApi = axios.create({
		baseURL: process.env.REACT_APP_AUTH_URL,
		headers: {
			'Content-Type': 'application/json',
			'VLR-Authorization': btoa(JSON.stringify({ id: process.env.REACT_APP_ID }))
		}
	})

	useEffect(() => {
		const hydrate = () =>
			new Promise<void>((resolve, reject) => {
				//Load user in session. If no user or session expired try to refresh token;
				const idToken = Cookies.get(TOKEN_COOKIE)
				if (idToken) {
					getUserDetails(idToken)
						.then(() => {
							setAuthState('SIGNED_IN')
							resolve()
						})
						.catch(err => {
							const errorMessage = errorHandle(err)
							if (errorMessage === 'Waiting for Access!') {
								setAuthState('WAITING_FOR_ACCESS')
							} else if (errorMessage === 'Access Denied!') {
								setAuthState('ACCESS_DENIED')
							} else if (errorMessage === 'Token is expired') {
								setAuthState('REFRESH_TOKEN')
							}
							reject(errorMessage)
						})
				} else {
					setAuthState('REFRESH_TOKEN')
					reject('Token is expired')
				}
			})

		hydrate()
			.catch(err => {
				toast.error(err.errorMessage)
			})
			.finally(() => {
				setLoading(false)
			})
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const refreshSession = async () => {
		try {
		    console.log('cookie removed')
			Cookies.remove(TOKEN_COOKIE, { path: '/', domain: process.env.REACT_APP_DOMAIN })
			setCurrentUser(null)
			setAuthState('REFRESH_TOKEN')
		} catch (e) {
			console.debug(e)
		}
	}

	const getUserDetails = (token: any) =>
		new Promise<void>((resolve, reject) => {
			//Load user details from server using access token;
			const headerToken = { Authorization: token }
			authApi
				.post('/user/detail', {}, { headers: headerToken })
				.then(response => {
					//If success save user detail data in state;
					const data = responseHandle(response)
					setCurrentUser(data.user)
					setSettings(data.rootSettings)
					resolve()
				})
				.catch(err => {
					console.error('currentAuthenticatedUser', err)
					reject(err)
				})
		})

	const signOut = async () => {
		try {
			Cookies.remove(TOKEN_COOKIE, { path: '/', domain: process.env.REACT_APP_DOMAIN })
			setCurrentUser(null)
			setAuthState('')
		} catch (e) {
			console.debug(e)
		}
	}

	const renderStore = isLoading ? (
		<LoadingOverlay open={true} />
	) : (
		<UserContext.Provider
			value={{
				currentUser,
				settings,
				authState,
				signOut,
				refreshSession
			}}
		>
			{children}
		</UserContext.Provider>
	)

	return renderStore
}
