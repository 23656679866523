/** @format */

import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import {
	Container,
	Grid,
	Typography,
	Card,
	CardActionArea,
	CardMedia,
	CardContent
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { UserContext } from '../stores/UserStore'

import PLPPIcon from '../assets/icons/plpp.svg'

const Home = () => {
	const theme = useTheme();

	const styles = {
		cardContainer: {
			marginTop: theme.spacing(3),
			display: 'flex',
			justifyContent: 'space-between',
			minHeight: '50vh'
		},
		titleContainer: {
			marginBottom: theme.spacing(3),
			marginTop: theme.spacing(3),
			textAlign: 'center'
		},
		titleHead: {
			color: 'rgb(89, 203, 228)',
			fontSize: '48px',
			fontWeight: '700',
			marginRight: '16px'
		},
		titleTail: {
			fontSize: '48px',
			fontWeight: '100',
			textTransform: "uppercase" as const
		},
		card: {
			maxWidth: '345'
		},
		card_action: {
			'&.MuiButtonBase-root.Mui-disabled': {
				backgroundColor: '#dddddd47'
			}
		},
		card_media: {
			height: '80px',
			margin: '40px 10px 10px',
			backgroundSize: 'contain'
		}
	}
	const { currentUser } = useContext(UserContext)


	return (
		<div style={{ backgroundColor: theme.palette.secondary.light, minHeight: '95vh' }}>
			<Container disableGutters sx={{ pt: theme.spacing(12), px: theme.spacing(5), margin: 0, maxWidth: 'none !important' }}>
				<Grid container spacing={3} sx={{ backgroundColor: "transparent" }}>
					<Grid item sx={styles.titleContainer} xs={12}>
						<Typography>
							<span style={styles.titleHead}>Hello</span>{' '}
							<span style={styles.titleTail}>
								{currentUser && currentUser.firstName + ' ' + currentUser.lastName}
							</span>
						</Typography>
					</Grid>
				</Grid>
				<Grid container spacing={3} sx={{ backgroundColor: "transparent" }}>
					<Grid item md={4} sm={12}>
					</Grid>
					<Grid item md={4} sm={12}>
						<Card sx={styles.card} id={'btnBestFit'}>
							<CardActionArea
								disabled={!currentUser || !currentUser.roles.GUEST}
								sx={styles.card_action}
								component={Link} to="/new-job"
							>
								<CardMedia sx={styles.card_media} image={PLPPIcon} title="BestFit" />
								<CardContent>
									<Typography align="center" variant="h6" color="textSecondary" component="h2">
										Best Fit
									</Typography>
								</CardContent>
							</CardActionArea>
						</Card>
					</Grid>
					<Grid item md={4} sm={12}>
					</Grid>
				</Grid>
			</Container>
		</div>
	)
}

export default Home
