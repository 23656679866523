import React from 'react';
import Paper from '@mui/material/Paper';
import { Typo } from '@digital-at-vallourec/steel-design-system-react';
import { useTranslation } from 'react-i18next'

function Footer() {
    const styles = {
        footer: {
            display:'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            padding: '10px',
        }
    };
    const { t } = useTranslation()

  return (
    <footer>
      <Paper sx={styles.footer} >
        <Typo variant={'body1'} sx={{color:'gray'}}>
          Copyright VAM 2021 | VAM IS A REGISTERED TRADEMARK OF VALLOUREC OIL AND GAS FRANCE
        </Typo>
        <Typo variant={'hyperlink2'} >
            {t('Data privacy & Cookies policy')}
        </Typo>
        <Typo variant={'hyperlink2'}>
            {t('Terms of use')}
        </Typo>
        <Typo variant={'hyperlink2'}>
            {t('Legal notice')}
        </Typo>

      </Paper>
    </footer>
  );
}

export default (Footer);