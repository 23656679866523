import React from 'react';
import { Button } from '@mui/material'
import { Typo } from '@digital-at-vallourec/steel-design-system-react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';

type BtnNextPageProps = {
	title?: string,
	subtitle?: string,
	link: string,
}

function BtnNextPage(props: BtnNextPageProps) {
  const styles = {
    button: {
        height: '100% !important',
        flexDirection:'column',
        padding:'10px 32px !important'
    },
    typoTitle: {
        fontWeight:'bold',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
  };

  return (
     <Link to={props.link}>
        <Button variant="secondary-dark" sx={styles.button} >
            <Typo variant={'subtitle1'} sx={styles.typoTitle}>
                {props.title} <ArrowForwardIcon fontSize='small' sx={{paddingLeft: '8px'}}/>
            </Typo>
            <div style={{width: '100%', textAlign: 'left', lineHeight:'none'}}>
                <Typo variant={'inputLabel'}>{props.subtitle}</Typo>
            </div>
        </Button>
     </Link>
  );
}

export default BtnNextPage;